:root {
  /* PURPLE */
  --color-purple: #9757d7;
  --color-purple-500: #45355f;
  --color-purple-700: #392061;
  --color-purple-800: #231b2f;
  --color-purple-900: #1a1b25;

  /* MAGENTA */
  --color-magenta-300: #822e81;

  /* YELLOW */
  --color-yellow-100: #f0f2a6;

  /* BLUE */
  --color-blue-100: #3f72ff;
  --color-blue-200: #325ac9;
  --color-blue-300: #2548a9;
  --color-blue-800: #070e22;
  --color-blue-900: #020418;

  /* GRAY */
  --color-gray: #555a67;
  --color-gray-50: #c2c9dd;
  --color-gray-100: #737887;
  --color-gray-150: #616161;
  --color-gray-200: #555a67;
  --color-gray-300: #3b3e49;
  --color-gray-350: #373c4b;
  --color-gray-400: #2d313b;
  --color-gray-450: #2a2d37;
  --color-gray-500: #23262f;
  --color-gray-600: #1a1c22;
  --color-gray-650: #1f222a;
  --color-gray-700: #15171f;
  --color-gray-800: #101314;
  --color-gray-900: #050506;

  /* BLACK */
  --color-black: #111315;

  /* PINK */
  --color-pink: #f5a2ff;
  --color-pink-light: #f6ccff;
  --color-pink-normal: #f5a2ff;
  --color-pink-normal-100: #f4a1ff;
  --color-pink-dark: #e991f9;

  /* MINT */
  --color-mint: #72dba1;
  --color-mint-light: #70f2ba;
  --color-mint-normal: #72dba1;
  --color-mint-dark: #32cc8a;
  --color-green-base: #3dde9c;

  /* LAVENDER */
  --color-lavender: #9887db;
  --color-lavender-light: #ab9bea;
  --color-lavender-normal: #9887db;
  --color-lavender-dark: #796ec4;

  /* CREAM */
  --color-cream: #fef6f3;
  --color-cream-dark: #ede5e2;
  --color-cream-base: #fff6f2;

  /* RED */
  --color-red: #ff5e5e;
  --color-red-rejected: #f17878;
  --color-red-error: #da5149;

  /* BLURPLE  */
  --color-blurple: #505dff;
  --color-blurple-light: #6477ff;
  --color-blurple-normal: #525cf5;
  --color-blurple-dark: #3c51e5;

  /* GRADIENT */
  --gradient-blurple-pink: linear-gradient(
    199deg,
    var(--color-blurple) -15.24%,
    #f5a2ff 110.07%
  );

  /* SPECIFIC */
  --selectedDivColor: #484848; /* #3772FF; */
  --color-yellow: #cfe400;
  --visionHolderColor: #7784fb;
  --textAreaColor: #1a1d1f;
  --inputFieldText: #7d818e;
  --nextPreviewButton: var(--color-blurple);
  --darkTextColor: #9a9fa5;
  --hoverColor: var(--color-highlight);
  --notStartedGray: #3a3d46;
  --menuBackground: #15171f;
  --menuSelected: #d3e349;
  --secondaryText: #c2c9dd;
  --lightPurple: #525cf5;
  --successColor: #ef9191;
  --grayScale: #fafafa;
  --blueHeader: #5b8bc7;
  --exitColor: #e96f68;
  --profileUsername: #fef6f3;
  --lightText: #fef6f3;
  --blurple: #525df6;
  --lavender: #ab9bea;
  --seafoam: #66c58f;
  --disabled-button-text: #737784;
  --border-light: #35383f;

  --color-gray-nav: rgba(0, 0, 0, 0.7);

  /* USED */
  --color-highlight: black;
  /* --color-primary: var(--color-gray-nav); */
  --color-primary: white;
  --color-secondary: var(--color-purple-700);
  --color-tertiary: var(--color-magenta-300);
  --color-page-background: white;
  --color-error: var(--color-red);
  --color-default-modal-bg: white;
  --color-text: black;
  --color-brand-primary: #0f73bc;
  --color-brand-complimentary: #f08c43;
}
